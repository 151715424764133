import React from "react"
import { useSelector } from "react-redux"
import { RootState } from "../../../redux/store"
import { useI18next } from "gatsby-plugin-react-i18next"

type Props = {
  toggleSidebar: () => void
  isSidebarOpen: boolean
  dinamycLink?: string
  platform?: "business" | "consumer" | "blog"
  offset?: [number, number]
}

const NavContext = React.createContext<Props>({
  toggleSidebar: () => { },
  isSidebarOpen: false,
  platform: "consumer",
  offset: [0, 26],
})

const NavigationProvider = ({
  children,
  dinamycLink,
  platform = "consumer",
}: {
  children: React.ReactNode
  dinamycLink?: string
  platform?: "business" | "consumer" | "blog"
}) => {
  const [isSidebarOpen, setIsSidebarOpen] = React.useState<boolean>(false)
  const { language } = useI18next()
  const {
    banner: { status },
  } = useSelector((state: RootState) => state.promo)
  const offset = React.useMemo(() => {
    if (platform === "business") {
      return [0, 26]
    } else {
      if (language !== "it") {
        return [0, 26]
      }
      return [0, -72]
      // no banner [0, 26]
      // banner[0, -72]
    }
  }, [platform, status]) as [number, number]

  const closeSidebar = React.useCallback(() => {
    document.body.classList.remove("sidebar-opened")
  }, [])

  const toggleSidebar = React.useCallback(() => {
    if (document.body.classList.contains("sidebar-opened")) {
      closeSidebar()
    } else {
      document.body.classList.add("sidebar-opened")
    }
    setIsSidebarOpen(!isSidebarOpen)
  }, [setIsSidebarOpen, isSidebarOpen, closeSidebar])

  return (
    <NavContext.Provider
      value={{
        isSidebarOpen,
        toggleSidebar,
        dinamycLink,
        platform,
        offset,
      }}
    >
      {children}
    </NavContext.Provider>
  )
}

const useNavigationContext = () => {
  return React.useContext(NavContext)
}

export { useNavigationContext, NavigationProvider }
