import React from "react"
import { useSelector } from "react-redux"
import { PromoElementPlatform } from "../../../redux/reducers/promoSlice"
import { RootState } from "../../../redux/store"
import { useBannerDispatcher } from "../hook"
import { ClosableBanner } from "./ClosableBanner"
import { useI18next } from "gatsby-plugin-react-i18next"

type Config = {
  showedPlatform: PromoElementPlatform[number]
}

export const PromoBanner = React.forwardRef<HTMLDivElement, Config>(
  ({ showedPlatform }, ref) => {
    const {
      openBanner,
      catchBannerUserPreference,
      closeBanner,
    } = useBannerDispatcher()

    const { language } = useI18next()

    const {
      banner: { seen, platform, status },
    } = useSelector((state: RootState) => state.promo)


    const isPlatformSupported = React.useMemo(() => {
      return platform.some(p => p === showedPlatform)
    }, [platform, showedPlatform])

    const isVisible = React.useMemo(() => {
      if (seen) return false
      if (isPlatformSupported /* && language === "it" */) {
        if (status === "open") return true
      }
      return false
    }, [seen, isPlatformSupported, status])

    React.useEffect(() => {
      const bannerUserPreference = catchBannerUserPreference()
      switch (bannerUserPreference) {
        case "hidden":
          if (isPlatformSupported) openBanner()
          break
        case "closed":
          closeBanner()
          break
        case "open":
          if (isPlatformSupported) openBanner()
          break
        default:
          break
      }
    }, [openBanner, closeBanner, catchBannerUserPreference])
    if (!isVisible) return null
    return <ClosableBanner onClose={closeBanner} ref={ref} />
  }
)
